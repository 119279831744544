import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type { QueryOptions } from '~/src/types/QueryOptions'
import type { paths } from '~/src/api/schema'
import type { DefaultProject } from '~/src/types/DefaultProject'

export const GET_DEFAULT_PROJECT = '/api/default_projects/{id}'
const DELETE_PROJECT = '/api/default_projects/{id}'
const POST_PROJECT = '/api/default_projects'
const PATCH_PROJECT = '/api/default_projects/{id}'

export function getDefaultProject({ params, query }: QueryOptions<paths[typeof GET_DEFAULT_PROJECT]['get']>) {
  return useQuery({
    ...query,
    queryKey: [
      GET_DEFAULT_PROJECT,
      { id: params.path.id },
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_DEFAULT_PROJECT, {
        params,
        signal,
      })
      return data as DefaultProject
    },
  })
}

export function deleteProject() {
  const client = useApi()
  return useMutation({
    mutationKey: [
      DELETE_PROJECT,
    ],
    mutationFn: async ({ params }: { params: QueryOptions<paths[typeof DELETE_PROJECT]['delete']>['params'] }) => {
      const { data } = await client.DELETE(DELETE_PROJECT, {
        params,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      return data
    },
  })
}

export function postProject() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      PATCH_PROJECT,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof POST_PROJECT]['post']>['body'] }) => {
      const client = useApi()
      const { data } = await client.POST(POST_PROJECT, {
        body,
        headers: { 'Content-Type': 'application/ld+json' },
      })
      return data as DefaultProject
    },
  })
}

export function patchProject() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      PATCH_PROJECT,
    ],
    mutationFn: async ({ params, body }: { params: QueryOptions<paths[typeof PATCH_PROJECT]['patch']>['params'], body: QueryOptions<paths[typeof PATCH_PROJECT]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(PATCH_PROJECT, {
        params,
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_DEFAULT_PROJECT] })
      return data as DefaultProject
    },
  })
}
